export const CBLES_COMP_DATA_COL = [
  {
    title: "Fee Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Accounting",
    dataIndex: "accounting",
    key: "accounting",
  },
  {
    title: "Economics",
    dataIndex: "economics",
    key: "economics",
  },
  {
    title: "Logistics & Supply Chain Management",
    dataIndex: "logistics",
    key: "logistics",
  },
  {
    title: "Mass Communication",
    dataIndex: "masscomm",
    key: "masscomm",
  },
];

export const CBLES_DATA = [
  {
    key: "1",
    description: "Tuition",
    accounting: "303,750",
    economics: "303,750",
    logistics: "303,750",
    masscomm: "303,750",
  },
  {
    key: "2",
    description: "Library Development",
    accounting: "50,000",
    economics: "50,000",
    logistics: "50,000",
    masscomm: "50,000",
  },
  {
    key: "3",
    description: "Examination",
    accounting: "150,000",
    economics: "150,000",
    logistics: "150,000",
    masscomm: "150,000",
  },
  {
    key: "4",
    description: "ICT Fee",
    accounting: "30,000",
    economics: "30,000",
    logistics: "30,000",
    masscomm: "30,000",
  },
  {
    key: "5",
    description: "Infrastructural Dev.",
    accounting: "50,000",
    economics: "50,000",
    logistics: "50,000",
    masscomm: "50,000",
  },
  {
    key: "6",
    description: "Lab, Studio & Wksp",
    accounting: "50,000",
    economics: "50,000",
    logistics: "50,000",
    masscomm: "50,000",
  },
  {
    key: "7",
    description: "Accommodation",
    accounting: "300,000",
    economics: "300,000",
    logistics: "300,000",
    masscomm: "300,000",
  },
  {
    key: "8",
    description: "E-Library",
    accounting: "100,000",
    economics: "100,000",
    logistics: "100,000",
    masscomm: "100,000",
  },
  {
    key: "9",
    description: "Departmental Fee",
    accounting: "5,000",
    economics: "5,000",
    logistics: "5,000",
    masscomm: "5,000",
  },
  {
    key: "10",
    description: "Maintenance",
    accounting: "50,000",
    economics: "50,000",
    logistics: "50,000",
    masscomm: "50,000",
  },
  {
    key: "11",
    description: "Sport",
    accounting: "20,000",
    economics: "20,000",
    logistics: "20,000",
    masscomm: "20,000",
  },
  {
    key: "12",
    description: "Medical",
    accounting: "50,000",
    economics: "50,000",
    logistics: "50,000",
    masscomm: "50,000",
  },
  {
    key: "13",
    description: "TOTAL FEE",
    accounting: "1,158,750",
    economics: "1,158,750",
    logistics: "1,158,750",
    masscomm: "1,158,750",
  },
  {
    key: "14",
    description: "First Semester",
    accounting: "695,250",
    economics: "695,250",
    logistics: "695,250",
    masscomm: "695,250",
  },
  {
    key: "15",
    description: "Second Semester",
    accounting: "463,500",
    economics: "463,500",
    logistics: "463,500",
    masscomm: "463,500",
  },
];

//Engineering and computing science
export const CECS_COMP_DATA_COL = [
  {
    title: "Fee Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Computer & Artificial Intelligence",
    dataIndex: "comptAi",
    key: "comptAi",
  },
  {
    title: "Cyber Security ",
    dataIndex: "cyberSecurity",
    key: "cyberSecurity",
  },
  {
    title: "Data Science",
    dataIndex: "dataScience",
    key: "dataScience",
  },
  {
    title: "Software Engineering",
    dataIndex: "softEng",
    key: "softEng",
  },
];

export const CECS_COMP_DATA = [
  {
    key: "1",
    description: "Tuition",
    comptAi: "403,750",
    cyberSecurity: "403,750",
    dataScience: "403,750",
    softEng: "403,750",
  },
  {
    key: "2",
    description: "Library Development",
    comptAi: "50,000",
    cyberSecurity: "50,000",
    dataScience: "50,000",
    softEng: "50,000",
  },
  {
    key: "3",
    description: "Examination",
    comptAi: "150,000",
    cyberSecurity: "150,000",
    dataScience: "150,000",
    softEng: "150,000",
  },
  {
    key: "4",
    description: "ICT Fee",
    comptAi: "30,000",
    cyberSecurity: "30,000",
    dataScience: "30,000",
    softEng: "30,000",
  },
  {
    key: "5",
    description: "Infrastructural Dev.",
    comptAi: "50,000",
    cyberSecurity: "50,000",
    dataScience: "50,000",
    softEng: "50,000",
  },
  {
    key: "6",
    description: "Lab, Studio & Wksp",
    comptAi: "150,000",
    cyberSecurity: "150,000",
    dataScience: "150,000",
    softEng: "150,000",
  },
  {
    key: "7",
    description: "Accommodation",
    comptAi: "300,000",
    cyberSecurity: "300,000",
    dataScience: "300,000",
    softEng: "300,000",
  },
  {
    key: "8",
    description: "E-Library",
    comptAi: "100,000",
    cyberSecurity: "100,000",
    dataScience: "100,000",
    softEng: "100,000",
  },
  {
    key: "9",
    description: "Departmental Fee",
    comptAi: "5,000",
    cyberSecurity: "5,000",
    dataScience: "5,000",
    softEng: "5,000",
  },
  {
    key: "10",
    description: "Maintenance",
    comptAi: "50,000",
    cyberSecurity: "50,000",
    dataScience: "50,000",
    softEng: "50,000",
  },
  {
    key: "11",
    description: "Sport",
    comptAi: "20,000",
    cyberSecurity: "20,000",
    dataScience: "20,000",
    softEng: "20,000",
  },
  {
    key: "12",
    description: "Medical",
    comptAi: "50,000",
    cyberSecurity: "50,000",
    dataScience: "50,000",
    softEng: "50,000",
  },
  {
    key: "13",
    description: "TOTAL FEE",
    comptAi: "1,358,750",
    cyberSecurity: "1,358,750",
    dataScience: "1,358,750",
    softEng: "1,358,750",
  },
  {
    key: "14",
    description: "First Semester",
    comptAi: "815,250",
    cyberSecurity: "815,250",
    dataScience: "815,250",
    softEng: "815,250",
  },
  {
    key: "15",
    description: "Second Semester",
    comptAi: "543,500",
    cyberSecurity: "543,500",
    dataScience: "543,500",
    softEng: "543,500",
  },
];

export const CECS_ENG_DATA_COL = [
  {
    title: "Fee Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Mechanical ",
    dataIndex: "mechEng",
    key: "mechEng",
  },
  {
    title: "Civil & Construction ",
    dataIndex: "civENg",
    key: "civENg",
  },
  {
    title: "Mechatronics ",
    dataIndex: "mechTEng",
    key: "mechTEng",
  },
  {
    title: "Industrial & Production ",
    dataIndex: "prodEng",
    key: "prodEng",
  },
  {
    title: "Aerospace ",
    dataIndex: "AeroEng",
    key: "AeroEng",
  },
  {
    title: "Electrical ",
    dataIndex: "eleEng",
    key: "eleEng",
  },
  {
    title: "Chemical ",
    dataIndex: "chemEng",
    key: "chemEng",
  },
  {
    title: "Systems ",
    dataIndex: "systemsEng",
    key: "systemsEng",
  },
];

export const CECS_ENG_DATA = [
  {
    key: "1",
    description: "Tuition",
    mechEng: "553,750",
    civENg: "553,750",
    mechTEng: "553,750",
    prodEng: "553,750",
    AeroEng: "553,750",
    eleEng: "553,750",
    chemEng: "553,750",
    systemsEng: "553,750",
  },
  {
    key: "2",
    description: "Library Development",
    mechEng: "50,000",
    civENg: "50,000",
    mechTEng: "50,000",
    prodEng: "50,000",
    AeroEng: "50,000",
    eleEng: "50,000",
    chemEng: "50,000",
    systemsEng: "50,000",
  },
  {
    key: "3",
    description: "Examination",
    mechEng: "150,000",
    civENg: "150,000",
    mechTEng: "150,000",
    prodEng: "150,000",
    AeroEng: "150,000",
    eleEng: "150,000",
    chemEng: "150,000",
    systemsEng: "150,000",
  },
  {
    key: "4",
    description: "ICT Fee",
    mechEng: "30,000",
    civENg: "30,000",
    mechTEng: "30,000",
    prodEng: "30,000",
    AeroEng: "30,000",
    eleEng: "30,000",
    chemEng: "30,000",
    systemsEng: "30,000",
  },
  {
    key: "5",
    description: "Infrastructural Dev.",
    mechEng: "50,000",
    civENg: "50,000",
    mechTEng: "50,000",
    prodEng: "50,000",
    AeroEng: "50,000",
    eleEng: "50,000",
    chemEng: "50,000",
    systemsEng: "50,000",
  },
  {
    key: "6",
    description: "Lab, Studio & Wksp",
    mechEng: "150,000",
    civENg: "150,000",
    mechTEng: "150,000",
    prodEng: "150,000",
    AeroEng: "150,000",
    eleEng: "150,000",
    chemEng: "150,000",
    systemsEng: "150,000",
  },
  {
    key: "7",
    description: "Accommodation",
    mechEng: "300,000",
    civENg: "300,000",
    mechTEng: "300,000",
    prodEng: "300,000",
    AeroEng: "300,000",
    eleEng: "300,000",
    chemEng: "300,000",
    systemsEng: "300,000",
  },
  {
    key: "8",
    description: "E-Library",
    mechEng: "100,000",
    civENg: "100,000",
    mechTEng: "100,000",
    prodEng: "100,000",
    AeroEng: "100,000",
    eleEng: "100,000",
    chemEng: "100,000",
    systemsEng: "100,000",
  },
  {
    key: "9",
    description: "Departmental Fee",
    mechEng: "5,000",
    civENg: "5,000",
    mechTEng: "5,000",
    prodEng: "5,000",
    AeroEng: "5,000",
    eleEng: "5,000",
    chemEng: "5,000",
    systemsEng: "5,000",
  },
  {
    key: "10",
    description: "Maintenance",
    mechEng: "50,000",
    civENg: "50,000",
    mechTEng: "50,000",
    prodEng: "50,000",
    AeroEng: "50,000",
    eleEng: "50,000",
    chemEng: "50,000",
    systemsEng: "50,000",
  },
  {
    key: "11",
    description: "Sport",
    mechEng: "20,000",
    civENg: "20,000",
    mechTEng: "20,000",
    prodEng: "20,000",
    AeroEng: "20,000",
    eleEng: "20,000",
    chemEng: "20,000",
    systemsEng: "20,000",
  },
  {
    key: "12",
    description: "Medical",
    mechEng: "50,000",
    civENg: "50,000",
    mechTEng: "50,000",
    prodEng: "50,000",
    AeroEng: "50,000",
    eleEng: "50,000",
    chemEng: "50,000",
    systemsEng: "50,000",
  },
  {
    key: "13",
    description: "TOTAL FEE",
    mechEng: "1,508,750",
    civENg: "1,508,750",
    mechTEng: "1,508,750",
    prodEng: "1,508,750",
    AeroEng: "1,508,750",
    eleEng: "1,508,750",
    chemEng: "1,508,750",
    systemsEng: "1,508,750",
  },
  {
    key: "14",
    description: "First Semester",
    mechEng: "905,250",
    civENg: "905,250",
    mechTEng: "905,250",
    prodEng: "905,250",
    AeroEng: "905,250",
    eleEng: "905,250",
    chemEng: "905,250",
    systemsEng: "905,250",
  },
  {
    key: "15",
    description: "Second Semester",
    mechEng: "603,500",
    civENg: "603,500",
    mechTEng: "603,500",
    prodEng: "603,500",
    AeroEng: "603,500",
    eleEng: "603,500",
    chemEng: "603,500",
    systemsEng: "603,500",
  },
];

//AGRICULTURE, LIFE & ENVIRONMENTAL SCIENCES

export const CALES_DATA_COL = [
  {
    title: "Fee Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Biochemistry",
    dataIndex: "biochem",
    key: "biochem",
  },
  {
    title: "Environmental Management & Toxicology",
    dataIndex: "emt",
    key: "emt",
  },
  {
    title: "Forensic Science ",
    dataIndex: "forensic",
    key: "forensic",
  },
  {
    title: "Microbiology",
    dataIndex: "microbiology",
    key: "microbiology",
  },
  {
    title: "Petroleum Chemistry ",
    dataIndex: "petChemistry",
    key: "petChemistry",
  },
  {
    title: "Agribusiness ",
    dataIndex: "agrBusiness",
    key: "agrBusiness",
  },
  {
    title: "Agricultural Economics ",
    dataIndex: "agrEconomics",
    key: "agrEconomics",
  },
];

export const CALES_DATA = [
  {
    key: "1",
    description: "Tuition",
    biochem: "553,750",
    emt: "553,750",
    forensic: "553,750",
    microbiology: "553,750",
    petChemistry: "553,750",
    agrBusiness: "100,750",
    agrEconomics: "100,750",
  },
  {
    key: "2",
    description: "Library Development",
    biochem: "50,000",
    emt: "50,000",
    forensic: "50,000",
    microbiology: "50,000",
    petChemistry: "50,000",
    agrBusiness: "50,000",
    agrEconomics: "50,000",
  },
  {
    key: "3",
    description: "Examination",
    biochem: "150,000",
    emt: "150,000",
    forensic: "150,000",
    microbiology: "150,000",
    petChemistry: "150,000",
    agrBusiness: "150,000",
    agrEconomics: "150,000",
  },
  {
    key: "4",
    description: "ICT Fee",
    biochem: "30,000",
    emt: "30,000",
    forensic: "30,000",
    microbiology: "30,000",
    petChemistry: "30,000",
    agrBusiness: "30,000",
    agrEconomics: "30,000",
  },
  {
    key: "5",
    description: "Infrastructural Dev.",
    biochem: "50,000",
    emt: "50,000",
    forensic: "50,000",
    microbiology: "50,000",
    petChemistry: "50,000",
    agrBusiness: "50,000",
    agrEconomics: "50,000",
  },
  {
    key: "6",
    description: "Lab, Studio & Wksp",
    biochem: "150,000",
    emt: "150,000",
    forensic: "150,000",
    microbiology: "150,000",
    petChemistry: "150,000",
    agrBusiness: "150,000",
    agrEconomics: "150,000",
  },
  {
    key: "7",
    description: "Accommodation",
    biochem: "300,000",
    emt: "300,000",
    forensic: "300,000",
    microbiology: "300,000",
    petChemistry: "300,000",
    agrBusiness: "300,000",
    agrEconomics: "300,000",
  },
  {
    key: "8",
    description: "E-Library",
    biochem: "100,000",
    emt: "100,000",
    forensic: "100,000",
    microbiology: "100,000",
    petChemistry: "100,000",
    agrBusiness: "100,000",
    agrEconomics: "100,000",
  },
  {
    key: "9",
    description: "Departmental Fee",
    biochem: "5,000",
    emt: "5,000",
    forensic: "5,000",
    microbiology: "5,000",
    petChemistry: "5,000",
    agrBusiness: "5,000",
    agrEconomics: "5,000",
  },
  {
    key: "10",
    description: "Maintenance",
    biochem: "50,000",
    emt: "50,000",
    forensic: "50,000",
    microbiology: "50,000",
    petChemistry: "50,000",
    agrBusiness: "50,000",
    agrEconomics: "50,000",
  },
  {
    key: "11",
    description: "Sport",
    biochem: "20,000",
    emt: "20,000",
    forensic: "20,000",
    microbiology: "20,000",
    petChemistry: "20,000",
    agrBusiness: "20,000",
    agrEconomics: "20,000",
  },
  {
    key: "12",
    description: "Medical",
    biochem: "50,000",
    emt: "50,000",
    forensic: "50,000",
    microbiology: "50,000",
    petChemistry: "50,000",
    agrBusiness: "50,000",
    agrEconomics: "50,000",
  },
  {
    key: "13",
    description: "TOTAL FEE",
    biochem: "1,508,750",
    emt: "1,508,750",
    forensic: "1,508,750",
    microbiology: "1,508,750",
    petChemistry: "1,508,750",
    agrBusiness: "985,750",
    agrEconomics: "985,750",
  },
  {
    key: "14",
    description: "First Semester",
    biochem: "905,250",
    emt: "905,250",
    forensic: "905,250",
    microbiology: "905,250",
    petChemistry: "905,250",
    agrBusiness: "591,450",
    agrEconomics: "591,450",
  },
  {
    key: "15",
    description: "Second Semester",
    biochem: "603,500",
    emt: "603,500",
    forensic: "603,500",
    microbiology: "603,500",
    petChemistry: "603,500",
    agrBusiness: "394,300",
    agrEconomics: "394,300",
  },
];

//TRISTATE COLLEGE OF MEDICINE
export const TRISTATE_MED_COL = [
  {
    title: "Fee Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Medicine",
    dataIndex: "medicine",
    key: "medicine",
  },
  {
    title: "Dentistry",
    dataIndex: "dentistry",
    key: "dentistry",
  },
  {
    title: "Pharmacy",
    dataIndex: "pharmacy",
    key: "pharmacy",
  },
  {
    title: "Med Lab Science",
    dataIndex: "medLab",
    key: "medLab",
  },
  {
    title: "Nursing Science",
    dataIndex: "nursing",
    key: "nursing",
  },
  {
    title: "Optometry",
    dataIndex: "optometry",
    key: "optometry",
  },
];

export const TRISTATE_MED_DATA = [
  {
    key: "1",
    description: "Tuition",
    medicine: "1,803,750",
    dentistry: "1,803,750",
    pharmacy: "1,803,750",
    medLab: "603,750",
    nursing: "603,750",
    optometry: "603,750",
  },
  {
    key: "2",
    description: "Library Development",
    medicine: "100,000",
    dentistry: "100,000",
    pharmacy: "100,000",
    medLab: "80,000",
    nursing: "80,000",
    optometry: "80,000",
  },
  {
    key: "3",
    description: "Examination",
    medicine: "150,000",
    dentistry: "150,000",
    pharmacy: "150,000",
    medLab: "150,000",
    nursing: "150,000",
    optometry: "150,000",
  },
  {
    key: "4",
    description: "ICT Fee",
    medicine: "30,000",
    dentistry: "30,000",
    pharmacy: "30,000",
    medLab: "30,000",
    nursing: "30,000",
    optometry: "30,000",
  },
  {
    key: "5",
    description: "Infrastructural Dev.",
    medicine: "150,000",
    dentistry: "150,000",
    pharmacy: "150,000",
    medLab: "100,000",
    nursing: "100,000",
    optometry: "100,000",
  },
  {
    key: "6",
    description: "Lab, Studio & Wksp",
    medicine: "350,000",
    dentistry: "350,000",
    pharmacy: "350,000",
    medLab: "150,000",
    nursing: "150,000",
    optometry: "150,000",
  },
  {
    key: "7",
    description: "Accommodation",
    medicine: "300,000",
    dentistry: "300,000",
    pharmacy: "300,000",
    medLab: "300,000",
    nursing: "300,000",
    optometry: "300,000",
  },
  {
    key: "8",
    description: "E-Library",
    medicine: "100,000",
    dentistry: "100,000",
    pharmacy: "100,000",
    medLab: "100,000",
    nursing: "100,000",
    optometry: "100,000",
  },
  {
    key: "9",
    description: "Departmental Fee",
    medicine: "10,000",
    dentistry: "10,000",
    pharmacy: "10,000",
    medLab: "5,000",
    nursing: "5,000",
    optometry: "5,000",
  },
  {
    key: "10",
    description: "Maintenance",
    medicine: "150,000",
    dentistry: "150,000",
    pharmacy: "150,000",
    medLab: "80,000",
    nursing: "80,000",
    optometry: "80,000",
  },
  {
    key: "11",
    description: "Sport",
    medicine: "20,000",
    dentistry: "20,000",
    pharmacy: "20,000",
    medLab: "20,000",
    nursing: "20,000",
    optometry: "20,000",
  },
  {
    key: "12",
    description: "Medical",
    medicine: "50,000",
    dentistry: "50,000",
    pharmacy: "50,000",
    medLab: "50,000",
    nursing: "50,000",
    optometry: "50,000",
  },
  {
    key: "13",
    description: "TOTAL FEE",
    medicine: "3,213,750",
    dentistry: "3,213,750",
    pharmacy: "3,213,750",
    medLab: "1,668,750",
    nursing: "1,668,750",
    optometry: "1,668,750",
  },
  {
    key: "14",
    description: "First Semester",
    medicine: "1,928,250",
    dentistry: "1,928,250",
    pharmacy: "1,928,250",
    medLab: "1,001,250",
    nursing: "1,001,250",
    optometry: "1,001,250",
  },
  {
    key: "15",
    description: "Second Semester",
    medicine: "1,285,500",
    dentistry: "1,285,500",
    pharmacy: "1,285,500",
    medLab: "667,500",
    nursing: "667,500",
    optometry: "667,500",
  },
];

export const TRISTATE_ANA_COL = [
  {
    title: "Fee Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Anatomy",
    dataIndex: "anatomy",
    key: "anatomy",
  },
  {
    title: "Audiology",
    dataIndex: "audiology",
    key: "audiology",
  },
  {
    title: "Nutrition & Dietics",
    dataIndex: "nutrition",
    key: "nutrition",
  },
  {
    title: "Physiology",
    dataIndex: "physiology",
    key: "physiology",
  },
];

export const TRISTATE_ANA_DATA = [
  {
    key: "1",
    description: "Tuition",
    anatomy: "453,750",
    audiology: "453,750",
    nutrition: "453,750",
    physiology: "453,750",
  },
  {
    key: "2",
    description: "Library Development",
    anatomy: "50,000",
    audiology: "50,000",
    nutrition: "50,000",
    physiology: "50,000",
  },
  {
    key: "3",
    description: "Examination",
    anatomy: "150,000",
    audiology: "150,000",
    nutrition: "150,000",
    physiology: "150,000",
  },
  {
    key: "4",
    description: "ICT Fee",
    anatomy: "30,000",
    audiology: "30,000",
    nutrition: "30,000",
    physiology: "30,000",
  },
  {
    key: "5",
    description: "Infrastructural Dev.",
    anatomy: "50,000",
    audiology: "50,000",
    nutrition: "50,000",
    physiology: "50,000",
  },
  {
    key: "6",
    description: "Lab, Studio & Wksp",
    anatomy: "150,000",
    audiology: "150,000",
    nutrition: "150,000",
    physiology: "150,000",
  },
  {
    key: "7",
    description: "Accommodation",
    anatomy: "300,000",
    audiology: "300,000",
    nutrition: "300,000",
    physiology: "300,000",
  },
  {
    key: "8",
    description: "E-Library",
    anatomy: "100,000",
    audiology: "100,000",
    nutrition: "100,000",
    physiology: "100,000",
  },
  {
    key: "9",
    description: "Departmental Fee",
    anatomy: "5,000",
    audiology: "5,000",
    nutrition: "5,000",
    physiology: "5,000",
  },
  {
    key: "10",
    description: "Maintenance",
    anatomy: "50,000",
    audiology: "50,000",
    nutrition: "50,000",
    physiology: "50,000",
  },
  {
    key: "11",
    description: "Sport",
    anatomy: "20,000",
    audiology: "20,000",
    nutrition: "20,000",
    physiology: "20,000",
  },
  {
    key: "12",
    description: "Medical",
    anatomy: "50,000",
    audiology: "50,000",
    nutrition: "50,000",
    physiology: "50,000",
  },
  {
    key: "13",
    description: "TOTAL FEE",
    anatomy: "1,408,750",
    audiology: "1,408,750",
    nutrition: "1,408,750",
    physiology: "1,408,750",
  },
  {
    key: "14",
    description: "First Semester",
    anatomy: "845,250",
    audiology: "845,250",
    nutrition: "845,250",
    physiology: "845,250",
  },
  {
    key: "15",
    description: "Second Semester",
    anatomy: "563,500",
    audiology: "563,500",
    nutrition: "563,500",
    physiology: "563,500",
  },
];
