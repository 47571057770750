import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #FFF",
  boxShadow: 24,
  p: 4,
};

export default function Disclaimer({ setOpen, open }) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", margin: "15px 0px" }}>
            OFFICIAL NOTICE
          </Typography>
          <img src={require("../../assets/disclaimer.jpg")} />
          <Box className="mt-10">
            <buuton
              className="border py-1.5 px-8 mt-10 cursor-pointer rounded-md text-white bg-teal-600"
              onClick={handleClose}
              style={{ marginTop: 20 }}>
              Close
            </buuton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
